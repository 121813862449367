@use 'normalize';

body {
    background: #444444;
    color: #fafafa;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
}

svg {
    fill: currentColor;
}

a {
    color: #ffd067;
    text-decoration: none;
    transition: filter 400ms;
}

a:hover {
    filter: brightness(120%);
}

h1 {
    font-weight: lighter;
    letter-spacing: 0.1rem;
    margin: 0;
    padding: 0;
    font-size: 2rem;
}

.social-links {
    padding: 1rem;
}

.social-links a {
    padding: 0.5rem;
}

@media(min-width: 600px) {
    h1 {
        font-size: 3rem;
    }
}

@media(min-width: 1200px) {
    h1 {
        font-size: 4rem;
    }
}